import facility from "@/services/facility";
import router from "../router/index";
import moment from "moment";

const getDefaultState = () => {
    return {
        organisationTypeList: [],
        credentialDetails: {},
        facilityList: [],
        facilityLocations: [],
        customerDetails: {},
        facilityConfig: [],        
        isFacilityConfigViolated: false,
        facilityUsers: [],
        publicListOrganisation: [],
    };
}

const state = getDefaultState()

const getters = {
    getOrganisationTypeList: state => state.organisationTypeList && state.organisationTypeList.length
        ? state.organisationTypeList.map(val => ({
            label: val.comments,
            code: val.organisation_type_id
        }))
        : [],
    credentialDetails: state => state.credentialDetails,
    getFacilityList: state => state.facilityList,
    getFacilityLocations: state => state.facilityLocations && state.facilityLocations.length
        ? state.facilityLocations.map(val => ({
            label: val.comments,
            code: val.location_id
        }))
        : [],
    getCustomerDetails: state => state.customerDetails,
    getCustomerDetailsById: state => state.customerDetails,
    getFacilityConfig: state => state.facilityConfig,
    isFacilityConfigViolated: state => state.isFacilityConfigViolated,
    getFacilityUsers: state => state.facilityUsers,
    getPublicListOrganisation: state => state.publicListOrganisation,
};

const mutations = {
    ["SET_ORGANISATION_TYPE_LIST"](state, payload) {
        state.organisationTypeList = payload;
    },
    ["SET_CREDENTIAL_DETAILS"](state, payload) {
        state.credentialDetails = payload;
    },
    ["SET_FACILITY_LIST"](state, payload) {
        state.facilityList = payload;
    },
    ["SET_FACILITY_LOCATIONS"](state, payload) {
        state.facilityLocations = payload;
    },
    ["SET_CUSTOMER_DETAILS"](state, payload) {
        state.customerDetails = payload;
    },
    ["RESET_STATE"](state, payload) {
        Object.assign(state, getDefaultState());
    },
    ["SET_FACILITY_CONFIG"](state, payload) {
        state.facilityConfig = payload;
    },
    ["RESET_FACILITY_CONFIG"](state, payload) {
        state.facilityConfig = [];
    },
    ["RESET_CUSTOMER_DETAILS"](state, payload) {
        state.customerDetails = {};
    },
    ['SET_CONFIG_VIOLATED'](state, payload){
        state.isFacilityConfigViolated = payload;
    },
    ["SET_FACILITY_USERS"](state, payload) {
        state.facilityUsers = payload;
    },
    ["RESET_FACILITY_USERS"](state, payload) {
        state.facilityUsers = [];
    },
    ["SET_LIST_ALL_ORGANISATIONS"](state, payload) {
        state.publicListOrganisation = payload;
    },
};

const actions = {
    fetchOrganisationTypeList: ({ state, commit, rootState, dispatch, getters }) => {
        return facility
            .getOrganisationTypeList()
            .then(async res => {
                commit("SET_ORGANISATION_TYPE_LIST", res.data);
            })
            .catch(err => {
                console.log("error fetching organisation type list", err);
                return err;
            });
    },
    fetchCredentialDetails: ({ state, commit, rootState, dispatch, getters }) => {
        const { getCustomerId } = getters;
        const customer_id = getCustomerId
        return facility
            .getNextCredentialDetail(customer_id)
            .then(async res => {
                commit("SET_CREDENTIAL_DETAILS", res.data);
            })
            .catch(err => {
                console.log("error fetch next username, password", err);
            });
    },
    addFacility: ({ state, commit, rootState, dispatch, getters }, payload) => {
        dispatch("showToast", { message: 'Saving...' })
        return facility
            .addFacility(payload)
            .then(async res => {
                dispatch("showToast", { class: 'bg-success text-white', message: 'Details saved successfully' })
                router.push("/facility-list");
            })
            .catch(err => {
                let msg = "Error while saving";
                if (err.message.indexOf("409") > 0) {
                    msg = "Facility already exists";
                }
                console.log("error creating facility", err);
                dispatch("showToast", { class: 'bg-danger text-white', message: msg })
                return err;
            });
    },
    fetchFacilityList: ({ state, commit, rootState, dispatch, getters }) => {
        return facility
            .getFacilityList()
            .then(async res => {
                commit("SET_FACILITY_LIST", res.data);
            })
            .catch(err => {
                console.log("error fetching facility list", err);
                return err;
            });
    },
    fetchFacilityLocations: ({ state, commit, rootState, dispatch, getters }) => {
        return facility
            .getFacilityLocation()
            .then(async res => {
                commit("SET_FACILITY_LOCATIONS", res.data);
            })
            .catch(err => {
                console.log("error fetching facility location list", err);
                return err;
            });
    },
    fetchCustomerDetails: ({ state, commit, rootState, dispatch, getters }) => {
        return facility
            .getCustomerDetails()
            .then(async res => {
                commit("SET_CUSTOMER_DETAILS", res.data);
            })
            .catch(err => {
                console.log("error fetching customer details", err);
                return err;
            })
    },
    initFetchFacilityDetails: ({ state, commit, rootState, dispatch, getters }, facilityId) => {
        commit("RESET_STATE");        
        if(facilityId){
            dispatch("fetchCustomerDetailsById", facilityId);
        }
        else{
            dispatch("fetchCustomerDetails");
        }
        dispatch("getTitles");
        dispatch("getLocations");
        dispatch("locationCountryInfo");
        dispatch("fetchOrganisationTypeList");
    },
    addFacilityDetails: ({ state, commit, rootState, dispatch, getters }, payload) => {
        const { getCustomerId } = getters;
        const customer_id = getCustomerId;
        dispatch("showToast", { message: 'Saving...' });
        return facility
            .addFacilityDetail(payload, customer_id)
            .then(async res => {
                dispatch("showToast", { class: 'bg-success text-white', message: 'Details saved successfully' })
                router.push("/candidate-list");
            })
            .catch(err => {
                let msg = "Error while Updating";
                console.log("error updating facility details", err);
                dispatch("showToast", { class: 'bg-danger text-white', message: msg })
                return err;
            });
    },
    fetchFacilityConfig: ({ state, commit, rootState, dispatch, getters }, facilityId) => {
        commit("RESET_FACILITY_CONFIG")
        return facility
            .getFacilityConfig(facilityId)
            .then(async res => {
                commit("SET_FACILITY_CONFIG", res.data);
            })
            .catch(err => {
                console.log("error fetching facility config", err);
                return err;
            })
    },
    updateFacilityConfig: ({ state, commit, rootState, dispatch, getters }, payload) => {
        return facility
            .updateFacilityConfig(payload, payload.customer_id)
            .then(async res => {
                await dispatch("clearAllToast");
                await dispatch("showToast", { class: 'bg-success text-white', message: 'Configuration saved successfully', autohide: 1000 })
            })
            .catch(err => {
                console.log("error while update facility config", err);
                return err;
            })
    },
    fetchCustomerDetailsById: ({ state, commit, rootState, dispatch, getters }, facilityId) => {
        return facility
            .getCustomerDetailsById(facilityId)
            .then(async res => {
                commit("SET_CUSTOMER_DETAILS", res.data);
            })
            .catch(err => {
                console.log("error fetching customer details", err);
                return err;
            })
    },
    initFetchFacilityEditDetails: ({ state, commit, rootState, dispatch, getters }, facilityId) => {
        commit("RESET_CUSTOMER_DETAILS");
        dispatch("fetchCustomerDetailsById", facilityId);
        dispatch("getTitles");
        dispatch("getLocations");
        dispatch("locationCountryInfo");
        dispatch("fetchOrganisationTypeList");
    },
    updateFacilityDetails: ({ state, commit, rootState, dispatch, getters }, payload) => {
        dispatch("showToast", { message: 'Saving...' });
        const data = payload.data;
        const customer_id = payload.customer_uid;
        return facility
            .addFacilityDetail(data, customer_id)
            .then(async res => {
                dispatch("showToast", { class: 'bg-success text-white', message: 'Details saved successfully' })
                dispatch("fetchFacilityList");
            })
            .catch(err => {
                let msg = "Error while saving";
                if (err.message.indexOf("409") > 0) {
                    msg = "Facility already exists";
                }
                console.log("error creating facility", err);
                dispatch("showToast", { class: 'bg-danger text-white', message: msg })
                return err;
            });
    },
    downloadCandidates(
        { state, commit, rootState, dispatch, getters },
        { customer_id, facility_name }
    ) {
        dispatch("showFixedToast",
            { class: 'bg-secondary text-white', message: 'Preparing to Download...' })
        return facility
            .downloadCandidatesDocument(customer_id)
            .then(res => {
                if (res.status === 200) {
                    let { data } = res;
                    let dateTime = moment.utc().utcOffset(+4).format('DD/MM/YYYY HH:mm:ss');
                    const fileName = facility_name.replace(/[^a-zA-Z0-9]/g, "") + "__" + dateTime+".xlsx";
                    const link = document.createElement("a");
                    if (link.download !== undefined) {
                        const url = URL.createObjectURL(data);
                        link.setAttribute("href", url);
                        link.setAttribute("download", fileName);
                        link.style.visibility = "hidden";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        dispatch("showToast", { class: 'bg-success text-white', message: 'Candidates downloaded successfully' });
                    }
                }
                dispatch("hideFixedToast")
            })
            .catch(err => {
                dispatch("showToast",
                    { class: 'bg-danger text-white', message: 'Download failed. Try again.' })
                console.error("error in download Candidates", err);
                return err;
            });
    },    
    addFacilityUser: ({state, commit, rootState, dispatch, getters}, payload) => {
        const { getCustomerId } = getters;
        const customer_id = getCustomerId;
        const data = payload.data;
        const facility_id = payload.facility_id;
        dispatch("showToast", { message: 'Saving...' })
        return facility
            .addFacilityUser({data, customer_id, facility_id})
            .then(async res => {
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'Admin/User created successfully' })
                router.push("/candidate-list");
                return res;
            })
            .catch(err => {
                dispatch("clearAllToast");
                let msg = "Error while saving";
                const {status,data} = err.response;
                if (status === 400 && 
                    data.detail.indexOf("email") > 0 && 
                    data.detail.indexOf("already exists") >0) {
                    msg = "Email already exists";
                }
                console.log("error creating facility admin/user",err);
                dispatch("showToast", { class: 'bg-danger text-white', message: msg })
                return err;
            });
    },
    resetPassword: ({ state, commit, rootState, dispatch, getters }, payload) => {
        return facility
        .recoveryPassword(payload)
        .then(async res => {
            dispatch("showToast", {message: 'Reset Password email has been sent'})
        })
        .catch(err => {
            let msg = "Error while Password reset";
            console.log("error facility password reset", err);
            dispatch("showToast", { class: 'bg-danger text-white', message: msg })
            return err;
        });
    },
    checkFacilityUser: ({state, commit, rootState, dispatch, getters}, payload) => {
        const { getCustomerId } = getters;
        const customer_id = getCustomerId;
        commit("SET_CONFIG_VIOLATED",false);
        return facility
            .checkFacilityUser({customer_id, payload})
            .then(async res => {
                commit("SET_CONFIG_VIOLATED",res.data);
                return res.data;
            })
            .catch(err => {
                console.log("error check Facility config violated", err);
                return err;
            });
    },
    fetchFacilityUserReport: ({state, commit, rootState, dispatch, getters}, payload) =>{
        commit("RESET_FACILITY_USERS");
        return facility
            .userReport(payload)
            .then(async res => {
                commit("SET_FACILITY_USERS", res.data);
                return res.data
            })
            .catch(err => {
                console.log("error while fetch user report", err);
            });
    },
    fetchPublicListOrganisation: ({commit}) => {
        return facility
          .fetchPublicListOrganisation()
          .then(res => {
              commit("SET_LIST_ALL_ORGANISATIONS",res.data);
              return res;
            })
            .catch(err => {
              console.log("Error Occured While Re-directing to the Organisation", err);
              return err;
            })
        }, 
};

export default { state, getters, mutations, actions };