import externalUsers from "@/services/externalUsers";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper";
import _ from "lodash";

const getDefaultState = () => {
    return {
        contactExternalUsers: [],
        externalUser: null,
    }
}

const state = getDefaultState();

const getters = {
    contactExternalUsers: state => state.contactExternalUsers,
    externalUser: state => state.externalUser,
}

const mutations = {
    ["SET_CONTACT_EXTERNAL_USERS"](state, data) {
        state.contactExternalUsers = data;
    },
    ["RESET_CONTACT_EXTERNAL_USERS"](state) {
        state.contactExternalUsers = [];
    },
    ["SET_EXTERNAL_USER"](state, data) {
        state.externalUser = data;
    }
}

const actions = {
    addExternalUsers({ commit, dispatch, getters }, payload = []) {
        const { getCustomerId: customer_uid } = getters;
        dispatch("showLoader");
        return externalUsers
            .addExternalUsers({ customer_uid, payload })
            .then(res => {
                return res;
            })
            .catch(err => {
                const message = "Error while Add External Users";
                dispatch("showToast", {
                    class: "text-white bg-danger",
                    message,
                })
                console.log(message, err);
                return err;
            })
            .finally(()=>{
                dispatch("hideLoader");
            })
    },
    getContactExternalUsers({ commit, dispatch, getters }, payload = {}) {
        commit("RESET_CONTACT_EXTERNAL_USERS");
        const { getCustomerId: customer_uid } = getters;
        const { organisation_id } = payload;
        let queryPayload = {};
        if (organisation_id)
            queryPayload = { ...queryPayload, organisation_id };
        if (customer_uid)
            queryPayload = { ...queryPayload, customer_uid };

        const query = getFilterQueryStringWithoutArray(queryPayload);
        return externalUsers
            .getContactExternalUsers({ query })
            .then(res => {
                const { data } = res;
                commit("SET_CONTACT_EXTERNAL_USERS", data);
                return res;
            })
            .catch(err => {
                const message = "Error While Fetch Contacts";
                dispatch("showToast", {
                    class: "text-white bg-danger",
                    message,
                })
                console.log(message, err);
                return err;
            })

    },
    getExternalUserByHashId({ commit, dispatch, getters }, hash_id) {
        const { getCustomerId: customer_uid } = getters;
        return externalUsers
            .getExternalUserByHashId({ customer_uid, hash_id })
            .then(res => {
                const { data } = res;
                commit("SET_EXTERNAL_USER", data);
                return res;
            })
            .catch(err => {
                const message = "Error While Fetch Exter User Info";
                dispatch("showToast", {
                    class: "text-white bg-danger",
                    message,
                })
                console.log(message, err);
                return err;
            })
    },
    createIEFByExternal({ commit, dispatch, getters }, payload) {
        return externalUsers
            .createIEFByExternal(payload)
            .then(res => {
                const { data } = res;
                dispatch("showToast", {
                    class: "text-white bg-success",
                    message: "Interview Evaluation Form Added Successfully",
                })
                commit("SET_INTERVIEW_FEEDBACK", [data]);
                return res;
            })
            .catch(err => {
                const message = "Error While Adding Interview Evaluation Form";
                console.log(message, err);
                dispatch("showToast", {
                    class: "text-white bg-danger",
                    message,
                })
                return err;
            })
    },
    updateIEFByExternal({ commit, dispatch, getters }, data) {
        const { feedback_id, payload } = data;
        return externalUsers
            .updateIEFByExternal({ feedback_id, payload })
            .then(res => {
                const { data } = res;
                commit("SET_INTERVIEW_FEEDBACK", [data]);
                dispatch("showToast", {
                    class: "text-white bg-success",
                    message: "Interview Evaluation Form Updated Successfully",
                })
                return res;
            })
            .catch(err => {
                const message = "Error While Update Interview Evaluation Form";
                console.log(message, err);
                dispatch("showToast", {
                    class: "text-white bg-danger",
                    message,
                })
                return err;
            })
    },
    updateExternalUserByHash({ commit, dispatch, getters }, data = {}) {
        const { hash_id, payload } = data;
        return externalUsers
            .updateExternalUserByHash({ hash_id, payload })
            .then(res => {
                dispatch("getExternalUserByHashId", hash_id);
                return res;
            })
            .catch(err => {
                const message = "Error While Update External User Info";
                console.log(message, err);
                dispatch("showToast", {
                    class: "text-white bg-danger",
                    message,
                })
                return err;
            })
    },
    fetchActionDetailByHashId({ commit, dispatch, getters }, action_hash_id) {
        return externalUsers
            .fetchActionDetailByHash(action_hash_id)
            .then(res => {
                const { data } = res;
                commit("SET_ACTION_DETAIL", data);
                return res;
            })
            .catch(err => {
                const message = "Error While Fetch Action Detail By HashID";
                console.log(message, err);
                dispatch("showToast", {
                    class: "text-white bg-danger",
                    message,
                })
                return err;
            })
    }
}

export default { state, getters, mutations, actions };