import router from "../router/index";
import dynamicOrgUrl from "../services/dynamicOrgUrl"
import candidate from "../services/candidate";
import { getLocalOrgDetail, IS_DOH_THEME, getFilterQueryStringWithoutArray, Variant } from "@/helpers/helper"
import { TFW_HOST_URL } from "@/service_urls";

const state = {
    orgDetail: {},
    dynamicOrgStaticFiles: [],
    isAcronymBasedLogin: false,
    getLoggedAcronym: JSON.parse(localStorage.getItem("orgDetail"))?.path_acronym || "",
    getOrgThemeStyle: JSON.parse(localStorage.getItem("orgDetail"))?.theme_style || "",
    getOrgVariant: JSON.parse(localStorage.getItem("orgDetail"))?.variant || "",
    setNewOrgAcronym: "",
    orgConfigThemeFiles: {},
    isShowContent: true,
    registrationStep: 0,
    alreadyRegisterCandidate: [],
};
const getters = {
    isAcronymBasedLogin: state => state.isAcronymBasedLogin,
    getOrgDetail: state => state.orgDetail,
    getDynamicOrgStaticFiles: state => state.dynamicOrgStaticFiles,
    getImages: state => {
        let orgFiles = state.dynamicOrgStaticFiles;
        if (IS_DOH_THEME) {
            return {
                header_logo: '/img/doh.png',
            }
        } else if (state.isAcronymBasedLogin) {
          // If does not render dynamically add this at the end of the string "?${new Date().getTime()}"
          return {
            header_logo: `${orgFiles?.header_logo_url}&ts=${new Date().getTime()}`,
            login_logo: `${orgFiles?.login_logo_url}&ts=${new Date().getTime()}`,
            customer_logo: `${orgFiles?.customer_logo_url}&ts=${new Date().getTime()}`,
            banner: `${orgFiles?.banner_path_url}&ts=${new Date().getTime()}`,
          };
        }
        return {
            header_logo: '/img/new_tf_logo.svg',
            login_logo: '/img/new_tf_logo.svg',
            customer_logo: '/img/talentfind_logo1.png',
            banner: '/img/new_home_bg.png'
        }
    },
    getLoggedAcronym: (state) => state.getLoggedAcronym,
    setNewOrgAcronym: (state) => state.setNewOrgAcronym,
    getOrgThemeStyle: (state) => state.getOrgThemeStyle,
    getOrgConfigThemeFiles: (state) => state.orgConfigThemeFiles,
    getOrgVariant: (state) => state.getOrgVariant,
    isSuperCustomer: (state, getters) => {
        const getOrgDetail = JSON.parse(localStorage.getItem("orgDetail"));
        return getOrgDetail?.customer_type_id === 1 || false
    },
    isShowContent: (state) => state.isShowContent,
    registerCandidateData: (state) => state.alreadyRegisterCandidate,
    getRegistrationStep: (state) => state.registrationStep,
};
const actions = {
    async fetchOrgDetailsByAcronym({ state, commit, getters, dispatch }, payload) {
        let acronym = payload || "";
        commit("SET_ACRONYM_BASED_LOGIN", true)
        dispatch("showLoader");
        const { getOrgDetail } = getters;
        let { params: { org_name: pid, cus_id: customer_id, org_id: organisation_id }, name } = router.currentRoute
        acronym = acronym || pid;
        name = name || "";
        const localOrgAcronym = [
            "Org Based Login",
            "Login",
            "Organisation Based Forgot",
            "UnSubscribe",
            "Register",
            "Organisation with Location Register",
            "Organisation Register",
            "Candidate With Email Register",
            "Forgot",
            "External IEF",
            "Direct Login",
            "PreRegisterCampaign",
            "RegisterCampaign"
        ]
        if (!localOrgAcronym.includes(name) && !payload) {
            acronym = getLocalOrgDetail()?.path_acronym
        }
        if (acronym && !payload && (acronym == getOrgDetail?.path_acronym)) {
            dispatch("hideLoader");
            return;
        }
        const currentURL =  window.location.hostname + (window.location.port ? ':' + window.location.port: '');
        if (["TFW",
            "TFW Login",
            "TFW Candidate Registration",
            "TFW Organisation Registration",].includes(name) || currentURL === TFW_HOST_URL) {
            acronym = 'tfw';
        }
        if (["Public Job Board", "Public Org Based Job Board"].includes(name)) {
            let param_details = { customer_id }
            if (organisation_id) param_details.organisation_id = organisation_id
            await dispatch('fetchAcronymByCustomerID', param_details).then((data) => {
                acronym = data
            });
        }
        localStorage.removeItem("orgDetail");
        if (!acronym) {
            commit("RESET_DYNAMIC_ORG_DETAIL");
            commit("RESET_DYNAMIC_ORG_STATIC_FILES");
            commit("SET_ACRONYM_BASED_LOGIN", false);
            commit("SET_LOGGED_ACRONYM", "")
            commit("SET_LOGGED_THEME_STYLE", "")
            commit("SET_LOGGED_VARIANT", "")
            dispatch("hideLoader");
            return;
        }
        if (payload) {
            acronym = payload
        }
        return dynamicOrgUrl.fetchOrgDetailsByAcronym(acronym).then((res) => {
            const { data } = res;
            if (!data) {
                dispatch("showToast", {
                    class: 'bg-danger text-white',
                    message: 'No Organization found! redirected to system login!'
                });
                commit("SET_ACRONYM_BASED_LOGIN", false)
                commit("RESET_DYNAMIC_ORG_STATIC_FILES");
                commit("RESET_DYNAMIC_ORG_DETAIL");
                commit("SET_LOGGED_ACRONYM", "")
                commit("SET_LOGGED_THEME_STYLE", "")
                commit("SET_LOGGED_VARIANT", "")
                router.push("/")
            } else {
                localStorage.setItem("orgDetail", JSON.stringify(data));
                commit("SET_DYNAMIC_ORG_DETAIL", data);
                commit("SET_LOGGED_ACRONYM", data?.path_acronym)
                commit("SET_LOGGED_THEME_STYLE", data?.theme_style)
                commit("SET_LOGGED_VARIANT", data?.variant)
                dispatch("fetchOrgFilesByAcronym", acronym)
                if (data?.variant == Variant.campaign) {
                    let path = `/${data?.path_acronym}/register/${data?.customer_uid}`
                    if (data.organisation_id) path += `/organisation/${data?.organisation_id}`
                    if (data.location_id) path += `/location/${data?.location_id}`
                    router.push({ path: path });
                }
            }
            dispatch("hideLoader");
            return res;
        }).catch(err => {
            console.log("error while fetching org data", err)
            dispatch("showToast", {
                class: "bg-danger text-white",
                message: "Invalid Path Acronym!"
            })
            router.push('/')
            return err
        })
    },
    fetchOrgFilesByAcronym({ commit, getters, dispatch }, acronym) {
        dispatch("showLoader");
        const { getOrgDetail } = getters;
        return dynamicOrgUrl.fetchOrgFilesByAcronym(acronym).then(res => {
            const { data } = res;
            commit("SET_DYNAMIC_ORG_STATIC_FILES", data);
            let orgFiles = { ...getOrgDetail, orgFiles: data }
            localStorage.setItem("orgDetail", JSON.stringify(orgFiles));
        }).catch(err => {
            console.log("error while fetching org files", err)
        }).finally(res => {
            dispatch("hideLoader");
            return res;
        })
    },
    postConfigThemeFiles({ commit, getters, dispatch }, payload) {
        let { acronym, documentName, ...rest } = payload;
        const file = new FormData();
        file.append(documentName, rest.file, rest.fileName);
        dispatch("showToast", { class: 'bg-success text-white', message: "Updating..." })
        return dynamicOrgUrl.postOrgFilesByAcronym(acronym, file).then(res => {
            dispatch("fetchConfigThemeFiles", acronym);
            dispatch("showToast", {
                class: "bg-success text-white",
                message: "File Uploaded Successfully",
            })
            return res;
        }).catch(err => {
            console.log("error while posting org files", err)
            return err
        })
    },
    fetchConfigThemeFiles({ commit, getters, dispatch }, acronym) {
        return dynamicOrgUrl.fetchOrgFilesByAcronym(acronym).then(res => {
            let { data } = res;
            commit("ORG_CONFIG_THEME_FILES", data)
            return res;
        }).catch(err => {
            console.log("error while fetching org files", err)
            return err
        })
    },
    deleteConfigThemeFiles({ commit, getters, dispatch }, payload) {
        let { acronym, documentName } = payload;
        let query = getFilterQueryStringWithoutArray({ acronym, [documentName]: true })
        return dynamicOrgUrl.deleteOrgFilesByAcronym(query).then(res => {
            dispatch("fetchConfigThemeFiles", acronym);
            dispatch("showToast", {
                class: "bg-success text-white",
                message: "File Deleted Successfully",
            })
            return res;
        }).catch(err => {
            console.log("error while deleting org files", err)
            return err
        })
    },
    isEmailBelongsToAcronym({ dispatch, commit }, email) {
        const { params: { org_name: pid }, path } = router.currentRoute
        let acronym = pid || "";
        if (path.includes("/tfw"))
            acronym = 'tfw'
        const email_encoded = encodeURIComponent(email)
        return dynamicOrgUrl.isEmailBelongsToAcronym(email_encoded, acronym).then(res => {
            return res;
        }).catch(err => {
            if (err?.response?.status === 400) {
                let payload = {
                    err_message: err.response.data.detail,
                    email: email_encoded
                }
                dispatch("checkCandidateAndRouteToRegister", payload)
            }
            console.log("error while fetching email belongings", err);
            return err;
        })
    },
    fetchAcronymByOrgDetail({ commit }, payload) {
        return dynamicOrgUrl.fetchAcronymByOrgDetail(payload).then(res => {
            let { data } = res;
            commit("PUBLIC_JOB_APPLYING_ORG_ACRONYM", data)
            return res;
        }).catch(err => {
            console.log("Error While applying job")
            return err;
        })
    },
    fetchAcronymByCustomerID({ commit }, payload) {
        let query = ""
        if (payload.organisation_id) query += `organisation_id=${payload.organisation_id}`
        return dynamicOrgUrl.fetchAcronymByCustomerID(payload.customer_id, query).then(res => {
            let { data } = res;
            return data
        }).catch(err => {
            console.log("Error While fetching acronym")
            return err;
        })
    },
    checkCandidateAndRouteToRegister({ dispatch, commit }, payload) {
        let { err_message, email } = payload;
        let query = { email }
        if (getLocalOrgDetail()?.customer_uid)
            query = { ...query, customer_uid: getLocalOrgDetail().customer_uid };
        const queryString = getFilterQueryStringWithoutArray(query)
        return candidate.getRegisteredHospitalsByEmail({ queryString }).then((response) => {
            if (response.data !== null && response.data?.length) {
                let {
                    path_acronym,
                    customer_uid,
                    organisation_id,
                    location_id,
                } = getLocalOrgDetail();
                if(path_acronym == 'tfw') {
                    const decodedEmail = decodeURIComponent(email);
                    response.data[0].email = decodedEmail;
                    commit("SET_ALREADY_REGISTER_CANDIDATE", response.data);
                    commit("SET_REGISTRATION_STEP", 1);
                    router.push(`/tfw/registration/candidate`)
                } else {
                    let path = `/${path_acronym}/register/${customer_uid}`;
                    if (organisation_id)
                        path += `/organisation/${organisation_id}/location/${location_id}`;
                    path += `?check_email=${email}`

                    const decodedEmail = decodeURIComponent(email);
                    response.data[0].email = decodedEmail;
                    commit("SET_ALREADY_REGISTER_CANDIDATE", response.data);
                    commit("SET_REGISTRATION_STEP", 5);
                    router.push({ path: path });
                }
            }
            else {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: err_message
                })
            }
        }).catch(err => {
            console.log(err)
            dispatch("showToast", {
                class: "bg-danger text-white",
                message: err_message
            })
        })
    },
    fetchAppcontent: ({ commit, getters }) => {
        //Refetch the current page without hard refresh
        commit("SET_IS_SHOW_CONTENT", false);
        setTimeout(() => {
            commit("SET_IS_SHOW_CONTENT", true);
        }, 10);
    }
};
const mutations = {
    ["SET_DYNAMIC_ORG_DETAIL"](state, payload) {
        state.orgDetail = payload;
    },
    ["RESET_DYNAMIC_ORG_DETAIL"](state) {
        state.orgDetail = "";
    },
    ["SET_DYNAMIC_ORG_STATIC_FILES"](state, payload) {
        state.dynamicOrgStaticFiles = payload;
    },
    ["RESET_DYNAMIC_ORG_STATIC_FILES"](state) {
        state.dynamicOrgStaticFiles = [];
    },
    ["SET_ACRONYM_BASED_LOGIN"](state, payload) {
        state.isAcronymBasedLogin = payload
    },
    ["SET_LOGGED_ACRONYM"](state, payload) {
        state.getLoggedAcronym = payload
    },
    ["SET_LOGGED_THEME_STYLE"](state, payload) {
        state.getOrgThemeStyle = payload
    },
    ["SET_LOGGED_VARIANT"](state, payload) {
        state.getOrgVariant = payload
    },
    ["PUBLIC_JOB_APPLYING_ORG_ACRONYM"](state, payload) {
        state.setNewOrgAcronym = payload
    },
    ["ORG_CONFIG_THEME_FILES"](state, payload) {
        state.orgConfigThemeFiles = payload
    },
    ['SET_IS_SHOW_CONTENT'](state, payload) {
        state.isShowContent = payload;
    },
    ['SET_ALREADY_REGISTER_CANDIDATE'](state, data) {
        state.alreadyRegisterCandidate = data;
    },
    ['SET_REGISTRATION_STEP'](state, step) {
        state.registrationStep = step;
    },
    ['CLEAR_CANDIDATE_DATA'](state) {
        state.registrationStep = 0;
        state.alreadyRegisterCandidate = [];
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
